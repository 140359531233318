<template>
  <CardsStack
    v-if="showCardStack"
    :cards="visibleCards"
    @cardAccepted="handleCardAccepted"
    @cardRejected="handleCardRejected"
    @cardSkipped="handleCardSkipped"
    @hideCard="removeCardFromDeck"
  />
</template>

<script>
import CardsStack from './components/CardsStack'

export default {
  name: 'CardsComponent',
  components: {
    CardsStack
  },

  props: {
    visibleCards: {
      type: Array,
      required: true
    },
    showCardStack: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      skippedCards: [],
      newComment: ''
    }
  },

  methods: {
    handleCardAccepted (e) {
      // console.log('handleCardAccepted', e)
      this.$store.state.data.tasks.mobileModal.show = true
      this.$store.state.data.tasks.mobileModal.data = {
        ...e,
        event: 'handleCardAccepted'
      }
    },
    handleCardRejected (e) {
      // console.log('handleCardRejected', e)
      this.$store.state.data.tasks.mobileModal.show = true
      this.$store.state.data.tasks.mobileModal.data = {
        ...e,
        event: 'handleCardRejected'
      }
    },
    handleCardSkipped (e) {
      // console.log('handleCardSkipped', e)
      this.skippedCards.push(e)
    },
    removeCardFromDeck (e) {
      // console.log('hideCard', e)
      this.visibleCards.shift()
    }
  }
}
</script>

<style lang="scss">
@import './styles/mixins.scss';
</style>
