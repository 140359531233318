<template>
  <ApolloQuery :query="require('@/graphql/queries/TasksTable.gql')" :variables="{ page, perPage, filter: getTasksFilter, sortField: sort.field, sortOrder: sort.order }" @result="doneLoadingTasks">
    <template slot-scope="{ result: { error, data }, isLoading }">
      <div class="messages apollo" v-if="error">
        <div class="messages__status error" v-if="error">Ошибка загрузки данных. Обратитесь в тех. поддержку</div>
      </div>
      <div :class="{'tbl--divided': $store.state.checkedTasks.length}" class="tbl">
        <div class="tbl__header" v-if="$store.state.data.tasks.showFilter">
          <div class="tbl__filter">
            <label class="tbl__filter__item">
              <v-select appendToBody
                        :options="$store.state.allUsers.map(item => ({ value: item.id, label: item.name }))"
                        @input="onUserChange"
                        placeholder="Выберите исполнителя"
                        :value="filter.responsible_user_id ? { value: filter.responsible_user_id, label: $store.state.allUsers.find(s => s.id === filter.responsible_user_id).name} : null"
              >
                <template slot="no-options">Нет результатов</template>
                <template #open-indicator="{ attributes }">
                  <div v-bind="attributes">
                    <DownIcon/>
                  </div>
                </template>
              </v-select>
            </label>
            <label class="tbl__filter__item">
              <v-select appendToBody
                        :options="$store.state.allGroups.map(item => ({ value: item.id, label: item.name }))"
                        @input="onGroupChange"
                        multiple
                        placeholder="Выберите подразделения"
                        :value="filter.group_id ? $store.state.allGroups.filter(s => filter.group_id.includes(s.id)).map(item => ({ value: item.id, label: item.name })) : []"
              >
                <template slot="no-options">Нет результатов</template>
                <template #open-indicator="{ attributes }">
                  <div v-bind="attributes">
                    <DownIcon/>
                  </div>
                </template>
              </v-select>
            </label>
            <label class="tbl__filter__item">
              <v-select appendToBody :options="taskStatusSelect" :reduce="status => status.value" label="label" @input="onStatusChange" placeholder="Выберите статус" v-model="filter.status">
                <template slot="no-options">Нет результатов</template>
                <template #open-indicator="{ attributes }">
                  <div v-bind="attributes">
                    <DownIcon/>
                  </div>
                </template>
              </v-select>
            </label>
            <label class="tbl__filter__item" v-if="showFilter('inn')">
              <input
                type="text"
                v-model="filter.inn"
                @change="onInnChange"
                placeholder="ИНН"
              />
            </label>
          </div>
          <div class="tbl__settings">
            <mq-layout :mq="[ 'lg', 'xlg', 'xl' ]">
              <a class="tbl__setting" href="#" @click.prevent="switchColumnModal">
                <SettingsIcon/>
              </a>
            </mq-layout>
            <div class="tbl__type">
              <a :class="{ active: $store.state.data.tasks.view === 'tableDo'}" @click.prevent="$store.state.data.tasks.view = 'tableDo'" href="#">
                <TypeCone/>
              </a>
              <a :class="{ active: $store.state.data.tasks.view === 'table'}" @click.prevent="$store.state.data.tasks.view = 'table'" href="#">
                <TypeTable/>
              </a>
              <mq-layout :mq="[ 'lg', 'xlg', 'xl' ]">
                <a :class="{ active: $store.state.data.tasks.view === 'kanban'}" @click.prevent="$store.state.data.tasks.view = 'kanban'" href="#">
                  <TypeKanban/>
                </a>
              </mq-layout>
            </div>
          </div>
        </div>
        <div class="tbl__actions" v-if="$store.state.checkedTasks.length">
          <div class="tbl__actions__main">
            <div class="tbl__actions__count">
              <span>Выбрано: {{ $store.state.checkedTasks.length }}</span>
            </div>
            <div class="tbl__actions__items">
              <div class="tbl__actions__item">
                <a :class="{'active': actionType === 'switchStage'}" @click.prevent="actionType = 'switchStage'" href="#">
                  <i aria-hidden="true">
                    <switch-icon/>
                  </i>
                  <span>Изменить статус</span>
                </a>
                <div class="table-actions__drop" v-if="actionType === 'switchStage'" v-click-outside="vcoConfig">
                  <date-picker
                    :formatter="momentFormat"
                    :shortcuts="shortcuts"
                    show-time-header
                    type="datetime"
                    v-model="period"
                    @change="setStatus"
                    inline
                    confirm
                    confirm-text="Изменить"
                  ></date-picker>
                  <!--                  <ul class="table-actions__drop-down" v-click-outside="vcoConfig">-->
                  <!--                    <li :key="index" v-for="(status, index) in stages">-->
                  <!--                      <a @click="setStatus(status)" href="#">{{ status }}</a>-->
                  <!--                    </li>-->
                  <!--                  </ul>-->
                </div>
              </div>
              <div class="tbl__actions__item">
                <a :class="{'active': actionType === 'switchResponsible'}" @click.prevent="actionType = 'switchResponsible'" href="#">
                  <i aria-hidden="true">
                    <responsible-icon/>
                  </i>
                  <span>Сменить ответственного</span>
                </a>
                <div class="table-actions__drop" v-if="actionType === 'switchResponsible'">
                  <div class="table-actions__container">
                    <v-select appendToBody :options="$store.state.allUsers.map(item => ({ value: item.id, label: item.name }))" @input="onResponsibleChange" placeholder="Выберите исполнителя" v-click-outside="vcoConfig">
                      <template slot="no-options">Нет результатов</template>
                      <template #open-indicator="{ attributes }">
                        <div v-bind="attributes">
                          <DownIcon/>
                        </div>
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tbl__actions__rest">
            <a @click.prevent="switchDeleteModal" class="red bold" href="#">
              <span>Удалить</span>
            </a>
          </div>
        </div>
        <mq-layout :mq="[ 'lg', 'xlg', 'xl' ]">
          <vue-good-table :columns="$store.state.data.tasks.columns" :pagination-options="{enabled: true, perPage: perPage}" :rows="data ? data.allTasks : []" :select-options="{ selectOnCheckboxOnly: true, selectionText: 'строка выбрана', clearSelectionText: 'очистить', enabled: true }" @on-selected-rows-change="selectedRowsChange" @on-sort-change="sortingField" compactMode>
            <template slot="table-row" slot-scope="props">
              <template v-if="props.column.field === 'phone'">
                <router-link v-if="props.row.element && props.row.element.id" :to="{name: getRouteOfElementType(props.row.element_type), params: {id: props.row.element.id }}">{{ props.row.phone ? props.row.phone : '' }}</router-link>
                <span v-else>{{ props.row.phone ? props.row.phone : '' }}</span>
              </template>
              <template v-if="props.column.field === 'task_type'">
                <router-link v-if="props.row.element && props.row.element.id" :to="{name: getRouteOfElementType(props.row.element_type), params: {id: props.row.element.id }}">{{ getCurrentTaskType(props.row.task_type) }}</router-link>
                <span v-else>{{ getCurrentTaskType(props.row.task_type) }}</span>
              </template>
              <template v-if="props.column.field === 'text'">
                <router-link v-if="props.row.element && props.row.element.id" :to="{name: getRouteOfElementType(props.row.element_type), params: {id: props.row.element.id }}">{{ props.row.text }}</router-link>
                <span v-else>{{ props.row.text }}</span>
              </template>
              <template v-if="props.column.field === 'responsible'">
                <router-link v-if="props.row.element && props.row.element.id && props.row.responsible_user" :to="{name: getRouteOfElementType(props.row.element_type), params: {id: props.row.element.id }}">{{ props.row.responsible_user.name }}</router-link>
                <span v-else>{{ props.row.group ? props.row.group.name : '' }}</span>
              </template>
              <template v-if="props.column.field === 'group_id'">
                <router-link v-if="props.row.element && props.row.element.id" :to="{name: getRouteOfElementType(props.row.element_type), params: {id: props.row.element.id }}">{{ props.row.group ? props.row.group.name : '' }}</router-link>
                <span v-else>{{ props.row.group ? props.row.group.name : '' }}</span>
              </template>
              <template v-if="props.column.field === 'company_id'">
                <router-link v-if="props.row.element && props.row.element.id" :to="{name: getRouteOfElementType(props.row.element_type), params: {id: props.row.element.id }}">{{ props.row.element ? props.row.element.company_name : '' }}</router-link>
                <span v-else>{{ props.row.element ? props.row.element.company_name : '' }}</span>
              </template>
              <template v-if="props.column.field === 'company_responsible'">
                <router-link v-if="props.row.element && props.row.element.id && props.row.element.responsible && props.row.element.responsible.id" :to="{name: getRouteOfElementType(props.row.element_type), params: {id: props.row.element.id }}">{{ props.row.element.responsible.name }}</router-link>
                <span v-else>{{ props.row.element ? props.row.element.company_name : '' }}</span>
              </template>
              <template v-if="props.column.field === 'complete_till_at'">
                <router-link v-if="props.row.element && props.row.element.id" :to="{name: getRouteOfElementType(props.row.element_type), params: {id: props.row.element.id }}">{{ getDateTime(props.row.complete_till_at) }}</router-link>
                <span v-else>{{ getDateTime(props.row.complete_till_at) }}</span>
              </template>
              <template v-if="props.column.field === 'status'">
                <router-link v-if="props.row.element && props.row.element.id" :class="getStatusColor(props.row)" :to="{name: getRouteOfElementType(props.row.element_type), params: {id: props.row.element.id }}" class="tag">{{ props.row.is_completed ? 'Завершенные' : getStatus(props.row.complete_till_at) }}</router-link>
                <span v-else :class="getStatusColor(props.row.complete_till_at)" class="tag">{{ props.row.is_completed ? 'Завершенные' : getStatus(props.row.complete_till_at) }}</span>
              </template>
              <template v-if="props.column.field === 'createdAt'">
                <router-link v-if="props.row.element" :to="{name: getRouteOfElementType(props.row.element_type), params: {id: props.row.element.id }}">{{ getDate(props.row.createdAt) }}</router-link>
                <span v-else>{{ getDate(props.row.createdAt) }}</span>
              </template>
              <template v-if="props.column.field === 'updatedAt'">
                <router-link v-if="props.row.element" :to="{name: getRouteOfElementType(props.row.element_type), params: {id: props.row.element.id }}">{{ getDate(props.row.updatedAt) }}</router-link>
                <span v-else>{{ getDate(props.row.updatedAt) }}</span>
              </template>
              <template v-if="props.column.field === 'call'">
                <div class="small-buttons">
                  <button type="button" @click="callPhone(props.row.phone)" class="btn btn--small" v-if="props.row.phone">
                    <PhoneIcon/>
                  </button>
                </div>
              </template>
            </template>
            <div slot="emptystate">Данных нет</div>
            <template slot="pagination-bottom" slot-scope="props">
              <div class="vgt-wrap__footer vgt-clearfix">
                <div class="footer__row-count vgt-pull-left">
                  <span class="footer__row-count__label">Показано</span>
                  <select @change="perPageChanged(props)" class="footer__row-count__select" v-model="perPage" ref="perPage">
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                    <option :value="100">100</option>
                    <option :value="200">200</option>
                  </select>
                </div>
              </div>
              <paginate :click-handler="setPage" :page-count="pageCount" :value="page + 1" prev-text="&lsaquo;" next-text="&rsaquo;" container-class="tbl__pagination vgt-pull-right">
                <span slot="prevContent">
                  <ArrowLeftIcon/>
                </span>
                <span slot="nextContent">
                  <ArrowRightIcon/>
                </span>
              </paginate>
            </template>
          </vue-good-table>
        </mq-layout>
        <mq-layout :mq="[ 'xsm', 'md' ]" class="tbl__container">
          <table>
            <thead>
            <tr>
              <th>
                <div class="tbl__cols">
                  <span :class="getSortableClass('created_by')" @click="sortingField2('responsible_user_id')">Задача от</span>
                  <span :class="getSortableClass('responsible_user_id')" @click="sortingField2('element_phone')">Контакт</span>
                  <!--                  <span :class="getSortableClass('responsible_user_id')" @click="sortingField2('responsible_user_id')">Кому</span>-->
                  <!--                  <span :class="getSortableClass('task_type')" @click="sortingField2('task_type')">Тип</span>-->
                </div>
              </th>
              <th>
                <div class="tbl__cols">
                  <span :class="getSortableClass('text')" @click="sortingField2('text')">Комментарий</span>
                  <span :class="getSortableClass('group_id')" @click="sortingField2('group_id')">Подразделение</span>
                  <span :class="getSortableClass('company_id')" @click="sortingField2('company_id')">Компания</span>
                </div>
              </th>
              <th>
                <div class="tbl__cols">
                  <span :class="getSortableClass('complete_till_at')" @click="sortingField2('complete_till_at')">Сделать до</span>
                  <span :class="getSortableClass('status')" @click="sortingField2('status')">Статус</span>
                  <span :class="getSortableClass('createdAt')" @click="sortingField2('createdAt')">Дата создания</span>
                  <span :class="getSortableClass('updatedAt')" @click="sortingField2('updatedAt')">Дата изменения</span>
                </div>
              </th>
            </tr>
            </thead>
            <tbody v-if="isLoading">
            <tr>
              <td colspan="3">Загрузка...</td>
            </tr>
            </tbody>
            <tbody v-else-if="data && data.allTasks && data.allTasks.length">
            <tr :key="index" v-for="(task, index) in data.allTasks">
              <td>
                <div class="tbl__content__container">
                  <checkbox :class-name="$store.state.checkedTasks.includes(task._id) ? 'active' : ''" v-model="$store.state.checkedTasks" :value="task._id"></checkbox>
                  <div class="tbl__content">
                    <router-link v-if="task.element && task.element.id" class="tag tag--green" :to="{name: getRouteOfElementType(task.element_type), params: {id: task.element.id }}">{{ getCurrentTaskType(task.task_type) }}</router-link>
                    <span v-else class="tag tag--green">{{ getCurrentTaskType(task.task_type) }}</span>
                    <template v-for="(phone, i) in task.phone ? task.phone.split(',') : ''">
                      <a :key="i" :href="'tel:' + phone" v-if="phone">{{ phone }}</a>
                    </template>
                    <!--                    <router-link :to="{name: getRouteOfElementType(task.element_type), params: {id: task.element.id }}">{{ task.created_by.name }}</router-link>-->
                    <!--                    <router-link :to="{name: getRouteOfElementType(task.element_type), params: {id: task.element.id }}">{{ task.created_by.name }}</router-link>-->
                    <!--                    <router-link :to="{name: getRouteOfElementType(task.element_type), params: {id: task.element.id }}">{{ task.responsible_user.name }}</router-link>-->
                  </div>
                </div>
              </td>
              <td>
                <div class="tbl__content">
                  <router-link v-if="task.element" :to="{name: getRouteOfElementType(task.element_type), params: {id: task.element.id }}">
                    {{ task.text }}
                  </router-link>
                  <span v-else>{{ getCurrentTaskType(task.task_type) }}</span>
                  <router-link v-if="task.element" :to="{name: getRouteOfElementType(task.element_type), params: {id: task.element.id }}">
                    {{ task.group ? task.group.name : '' }}
                  </router-link>
                  <span v-else>{{ task.group ? task.group.name : '' }}</span>
                  <router-link v-if="task.element" :to="{name: getRouteOfElementType(task.element_type), params: {id: task.element.id }}">
                    {{ task.element ? task.element.company_name : '' }}
                  </router-link>
                </div>
              </td>
              <td>
                <div class="tbl__content">
                  <span>{{ getDateTime(task.complete_till_at) }}</span>
                  <span class="tag" :class="getStatusColor(task)">{{ task.is_completed ? 'Завершенные' : getStatus(task.complete_till_at) }}</span>
                </div>
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="5">Нет результатов</td>
            </tr>
            </tbody>
          </table>
        </mq-layout>
        <mq-layout :mq="[ 'xs', 'sm' ]" class="tbl__container tbl__mobile">
          <table>
            <tbody v-if="isLoading">
            <tr>
              <td colspan="1">Загрузка...</td>
            </tr>
            </tbody>
            <tbody v-else-if="data && data.allTasks && data.allTasks.length">
            <tr :key="index" v-for="(task, index) in data.allTasks">
              <td>
                <div class="tbl__content__container">
                  <checkbox :class-name="$store.state.checkedTasks.includes(task._id) ? 'active' : ''" v-model="$store.state.checkedTasks" :value="task._id"></checkbox>
                  <div class="tbl__content">
                    <router-link v-if="task.element && task.element.id" class="tag tag--green" :to="{name: getRouteOfElementType(task.element_type), params: {id: task.element.id }}">{{ getCurrentTaskType(task.task_type) }}</router-link>
                    <span v-else class="tag tag--green">{{ getCurrentTaskType(task.task_type) }}</span>
                    <template v-for="(phone, i) in task.phone ? task.phone.split(',') : ''">
                      <a :key="i" :href="'tel:' + phone" v-if="phone">{{ phone }}</a>
                    </template>
                    <router-link v-if="task.element && task.element.id" :to="{name: getRouteOfElementType(task.element_type), params: {id: task.element.id }}">{{ task.text }}</router-link>
                    <span v-else>{{ task.text }}</span>
                    <router-link v-if="task.element && task.element.id" :to="{name: getRouteOfElementType(task.element_type), params: {id: task.element.id }}">{{ task.group ? task.group.name : '' }}</router-link>
                    <span v-else>{{ task.group ? task.group.name : '' }}</span>
                    <router-link v-if="task.element && task.element.id" :to="{name: getRouteOfElementType(task.element_type), params: {id: task.element.id }}">{{ task.element ? task.element.company_name : '' }}</router-link>
                    <span v-else>{{ task.element ? task.element.company_name : '' }}</span>
                    <span>{{ getDateTime(task.complete_till_at) }}</span>
                    <span class="tag" :class="getStatusColor(task)">{{ task.is_completed ? 'Завершенные' : getStatus(task.complete_till_at) }}</span>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="1">Нет результатов</td>
            </tr>
            </tbody>
          </table>
        </mq-layout>
      </div>
      <mq-layout :mq="[ 'xs', 'sm', 'xsm', 'md' ]" class="tbl__footer">
        <div class="tbl__footer__item left"></div>
        <div class="tbl__footer__item">
          <paginate :page-count="pageCount" :value="page + 1" :click-handler="setPage" prev-text="&lsaquo;" next-text="&rsaquo;" container-class="tbl__pagination vgt-pull-right">
            <span slot="prevContent">
              <ArrowLeftIcon/>
            </span>
            <span slot="nextContent">
              <ArrowRightIcon/>
            </span>
          </paginate>
        </div>
        <div class="tbl__footer__item right">
          <label class="tbl__pagination__info">
            Показано
            <span class="bold">{{ perPage }}</span>
            из
            <span class="bold">{{ _allTasksMeta }}</span>
          </label>
        </div>
      </mq-layout>
    </template>
  </ApolloQuery>
</template>

<script>
import gql from 'graphql-tag'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import SwitchIcon from '@/components/svg/SwitchIcon'
import ResponsibleIcon from '@/components/svg/ResponsibleIcon'
import moment from 'moment'
import { extendMoment } from 'moment-range'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import UPDATE_TASKS from '@/graphql/mutations/UpdateTasks.gql'
import SettingsIcon from '@/components/svg/settings'
import TypeCone from '@/components/svg/TypeCone'
import TypeTable from '@/components/svg/TypeTable'
import TypeKanban from '@/components/svg/TypeKanban'
import ArrowLeftIcon from '@/components/svg/arrow_left'
import ArrowRightIcon from '@/components/svg/arrow_right'
import DownIcon from '@/components/svg/DownIcon'
import PhoneIcon from '@/components/svg/phone'
// import MAKE_CALL from '@/graphql/mutations/MakeCall.gql'

const _graphQlHelper = new GraphQLHelper()
const _moment = extendMoment(moment)
const Today = _moment()
const Tomorrow = _moment().add(1, 'days')
const Week = _moment().endOf('week')
const NextWeek = _moment().endOf('week').add(1, 'week')

export default {
  name: 'TasksTable',
  components: {
    PhoneIcon,
    DownIcon,
    ArrowRightIcon,
    ArrowLeftIcon,
    TypeKanban,
    TypeTable,
    TypeCone,
    SettingsIcon,
    ResponsibleIcon,
    SwitchIcon,
    DatePicker
  },
  props: {
    callCenter: {
      type: Boolean,
      required: true
    },
    createdBy: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      vcoConfig: {
        handler: () => {
          this.actionType = null
        },
        middleware (event) {
          let name = event.target.getAttribute('class') || ''
          return name !== null ? !name.includes('mx-') : true
        },
        events: ['dblclick', 'click'],
        isActive: true
      },
      actionType: null,
      page: 0,
      perPage: 50,
      sort: {
        field: 'createdAt',
        order: 'desc'
      },
      period: new Date(Date.now()),
      momentFormat: {
        stringify: (date) => {
          return date ? moment(date).format('DD.MM.YYYY HH:mm') : ''
        },
        parse: (value) => {
          return value ? moment(value, 'DD.MM.YYYY HH:mm').toDate() : null
        }
      },
      shortcuts: [
        {
          text: 'Через 15 минут',
          onClick () {
            return moment().add(15, 'minutes').toDate()
          }
        },
        {
          text: 'Через 30 минут',
          onClick () {
            return moment().add(30, 'minutes').toDate()
          }
        },
        {
          text: 'Через час',
          onClick () {
            return moment().add(1, 'h').toDate()
          }
        },
        {
          text: 'Сегодня',
          onClick () {
            return moment().endOf('d').toDate()
          }
        },
        {
          text: 'Завтра',
          onClick () {
            return moment().add(1, 'd').endOf('d').toDate()
          }
        },
        {
          text: 'До конца недели',
          onClick () {
            return moment().endOf('w').endOf('d').toDate()
          }
        },
        {
          text: 'Через неделю',
          onClick () {
            return moment().endOf('w').add(1, 'd').endOf('d').toDate()
          }
        },
        {
          text: 'Через месяц',
          onClick () {
            return moment().endOf('M').add(1, 'd').endOf('d').toDate()
          }
        },
        {
          text: 'Через год',
          onClick () {
            return moment().endOf('y').add(1, 'd').endOf('d').toDate()
          }
        },
        {
          text: 'Завершено',
          onClick () {
            return 'completed'
          }
        }
      ],
      filter: {
        q: '',
        responsible_user_id: null,
        status: 'uncompleted',
        complete_till_at: null,
        group_id: [],
        is_completed: false,
        call_center: false
      },
      taskStatusSelect: [
        {
          label: 'На сегодня',
          value: 'today'
        },
        {
          label: 'На завтра',
          value: 'tomorrow'
        },
        {
          label: 'Эта неделя',
          value: 'week'
        },
        {
          label: 'След. неделя',
          value: 'next_week'
        },
        // {
        //   label: 'После след. недели',
        //   value: 'after_next_week'
        // },
        {
          label: 'Просроченные',
          value: 'expired'
        },
        {
          label: 'Завершенные',
          value: 'completed'
        },
        {
          label: 'Не завершенные',
          value: 'uncompleted'
        }
      ],
      stages: ['Просроченные', 'На сегодня', 'На завтра', 'Эта неделя', 'След. неделя'
        // 'После след. недели'
      ],
      taskTypeSelect: [
        {
          value: 'Call',
          name: 'Звонок'
        }, {
          value: 'Meeting',
          name: 'Переговоры'
        }, {
          value: 'WriteEmail',
          name: 'Написать письмо'
        }, {
          value: 'Get_docs',
          name: 'Получить документы'
        }, {
          value: 'Action',
          name: 'Прочее'
        }
      ],
      // eslint-disable-next-line vue/no-reserved-keys
      _allTasksMeta: 0
    }
  },
  watch: {
    // 'callCenter' (val) {
    //   this.filter.call_center = val
    // },
    '$store.state.data.tasks.sort2' () {
      if (this.$store.state.data.tasks.sort2) {
        this.filter.status = this.$store.state.data.tasks.sort2
      } else {
        this.filter.status = 'uncompleted'
      }
      this.onStatusChange()
    },
    '$store.state.data.tasks.sortDate' () {
      if (this.$store.state.data.tasks.sort2) {
        this.filter.status = this.$store.state.data.tasks.sort2
      } else {
        this.filter.status = 'uncompleted'
      }
      this.onStatusChange()
    }
  },
  created () {
    // this.filter.call_center = this.callCenter
    this.page = this.$store.state.data.tasks.page
    this.perPage = this.$store.state.data.tasks.perPage
    this.sort = this.$store.state.data.tasks.sort
    this.filter = this.$store.state.data.tasks.filter
  },
  beforeDestroy () {
    this.$store.state.data.tasks.page = this.page
    this.$store.state.data.tasks.perPage = this.perPage
    this.$store.state.data.tasks.sort = this.sort
    this.$store.state.data.tasks.filter = this.filter
  },
  computed: {
    selectAll: {
      get () {
        return this.$store.state.allTasks ? this.$store.state.checkedTasks.length === this.$store.state.allTasks.length : false
      },
      set (value) {
        if (value) {
          this.$store.state.checkedTasks = this.$store.state.allTasks.map(item => item._id)
        } else {
          this.$store.state.checkedTasks = []
        }
      }
    },
    pageCount () {
      return Math.ceil(this._allTasksMeta / this.perPage)
    },
    getTasksFilter () {
      let currentFilter = {}
      if (this.filter.q) currentFilter.q = this.filter.q
      if (this.filter.inn) currentFilter.company = { inn: this.filter.inn }
      if (this.filter.complete_till_at) currentFilter.complete_till_at = this.filter.complete_till_at
      if (this.filter.responsible_user_id) currentFilter.responsible_user_id = this.filter.responsible_user_id
      if (this.filter.group_id.length) currentFilter.group_id = this.filter.group_id
      if (this.filter.is_completed !== null) currentFilter.is_completed = this.filter.is_completed
      // if (this.filter.call_center) currentFilter.callCenter = this.filter.call_center
      return currentFilter
    }
  },
  apollo: {
    _allTasksMeta () {
      return {
        query: gql`query($filter: TaskFilter) {
          _allTasksMeta(filter: $filter) {
            count
          }
        }`,
        variables () {
          return {
            filter: this.getTasksFilter
          }
        },
        update: (data) => {
          this.$nextTick(() => {
            this.$store.state.loadedPages.push({ title: this.$route.meta.title, route: this.$route.name })
          })
          return data._allTasksMeta.count || 0
        }
      }
    }
  },
  methods: {
    showFilter (filterField) {
      const findedFilter = this.$store.state.data.tasks.filters.find(filter => (filterField === filter.field))

      if (!findedFilter) return false

      return !findedFilter.hidden
    },
    selectedRowsChange ({ selectedRows }) {
      this.$store.state.checkedTasks = selectedRows.map(row => row.id)
    },
    perPageChanged (props) {
      props.perPageChanged({ currentPerPage: this.perPage })
    },
    getStatusColor (item) {
      if (item.is_completed) {
        return ''
      } else {
        switch (this.getStatus(item.complete_till_at)) {
          case 'На сегодня':
            return 'tag--purple'
          case 'На завтра':
            return 'tag--green'
          case 'Эта неделя':
            return 'tag--orange'
          case 'След. неделя':
            return 'tag--dark-blue'
          // case 'После след. недели':
          //   return 'tag--yellow'
          case 'Просроченные':
            return 'tag--red'
        }
      }
    },
    getStatusBasedDate (status) {
      switch (status) {
        case 'Завершенные':
          return moment().format('YYYY-MM-DDTHH:mm')
        case 'Просроченные':
          return moment().subtract(1, 'days').format('YYYY-MM-DDTHH:mm')
        // case 'После след. недели':
        //   return moment().add(2, 'weeks').format('YYYY-MM-DDTHH:mm')
        case 'След. неделя':
          return moment().add(1, 'weeks').format('YYYY-MM-DDTHH:mm')
        case 'Эта неделя':
          return moment().add(2, 'days').format('YYYY-MM-DDTHH:mm')
        case 'На завтра':
          return moment().add(1, 'days').format('YYYY-MM-DDTHH:mm')
        case 'На сегодня':
          return moment().startOf('day').format('YYYY-MM-DDTHH:mm')
      }
    },
    onInnChange (e) {
      this.setPage(1)
    },
    onResponsibleChange (user) {
      if (user) {
        this.$apollo.mutate({
          mutation: UPDATE_TASKS,
          variables: {
            ids: this.$store.state.checkedTasks,
            responsible_user_id: user.value
          }
        }).then(() => {
          this.$router.go()
        }).catch(error => {
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
      }
    },
    switchDeleteModal () {
      this.$store.state.data.tasks.deleteModal = !this.$store.state.data.tasks.deleteModal
    },
    switchColumnModal () {
      this.$store.state.data.tasks.columnModal = !this.$store.state.data.tasks.columnModal
    },
    setStatus () {
      let data
      if (this.period === null) {
        data = {
          ids: this.$store.state.checkedTasks,
          is_completed: true,
          complete_till_at: moment().format('YYYY-MM-DDTHH:mm')
        }
      } else {
        data = {
          ids: this.$store.state.checkedTasks,
          is_completed: false,
          complete_till_at: moment(this.period).format('YYYY-MM-DDTHH:mm')
        }
      }
      this.$apollo.mutate({
        mutation: UPDATE_TASKS,
        variables: data
      }).then(() => {
        this.$router.go()
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    doneLoadingTasks ({ data }) {
      this.$store.commit('allTasks', { data, isMore: false })
    },
    setPage (page) {
      this.page = page - 1
    },
    getRange (status) {
      switch (status) {
        case 'today':
          return {
            start: _moment(Today).startOf('day').format('YYYY-MM-DD'),
            end: _moment(Today).startOf('day').add(1, 'days').format('YYYY-MM-DD')
          }
        case 'tomorrow':
          return {
            start: _moment(Tomorrow).startOf('day').format('YYYY-MM-DD'),
            end: _moment(Tomorrow).startOf('day').add(1, 'days').format('YYYY-MM-DD')
          }
        case 'week':
          return {
            start: _moment(Today).startOf('day').format('YYYY-MM-DD'),
            end: _moment(Week).endOf('week').add(1, 'days').format('YYYY-MM-DD')
          }
        case 'next_week':
          return {
            start: _moment(NextWeek).startOf('week').format('YYYY-MM-DD'),
            end: _moment(NextWeek).endOf('week').add(1, 'days').format('YYYY-MM-DD')
          }
        case 'month':
          return {
            start: _moment(Today).startOf('month').format('YYYY-MM-DD'),
            end: _moment(Today).endOf('month').add(1, 'days').format('YYYY-MM-DD')
          }
        case 'after_next_week':
          return {
            start: _moment(NextWeek).endOf('week').format('YYYY-MM-DD')
          }
        case 'expired':
          return {
            end: _moment(Today).startOf('day').format('YYYY-MM-DD')
          }
        case 'period':
          return {
            start: _moment(this.$store.state.data.tasks.sortDate[0]).startOf('day').format('YYYY-MM-DD'),
            end: _moment(this.$store.state.data.tasks.sortDate[1]).endOf('day').format('YYYY-MM-DD')
          }
        default:
          return null
      }
    },
    onStatusChange (e) {
      if (e) {
        this.filter.status = e
      }
      switch (this.filter.status) {
        case null:
          this.filter.complete_till_at = null
          this.filter.is_completed = null
          break
        case 'uncompleted':
          this.filter.complete_till_at = null
          this.filter.is_completed = false
          break
        case 'completed':
          this.filter.complete_till_at = null
          this.filter.is_completed = true
          break
        default:
          this.filter.complete_till_at = this.getRange(this.filter.status)
          this.filter.is_completed = false
          break
      }
      this.setPage(1)
    },
    onGroupChange (e) {
      this.filter.group_id = e.map(item => item.value)
      this.setPage(1)
    },
    onUserChange (select) {
      if (select) {
        this.filter.responsible_user_id = select.value
        const user = this.$store.state.allUsers.filter(item => item.id === select.value)
        this.filter.group_id = user[0] ? user[0].group.id : null
      } else {
        this.filter.responsible_user_id = null
        this.filter.group_id = this.$store.state.me.group.id
      }
      this.setPage(1)
    },
    getStatus (date) {
      const target = _moment(date)
      if (new Date(date).getTime() < new Date().getTime()) {
        return 'Просроченные'
      } else if (_moment().range(_moment(Today).startOf('day'), _moment(Today).endOf('day')).contains(target)) {
        return 'На сегодня'
      } else if (_moment().range(_moment(Tomorrow).startOf('day'), _moment(Tomorrow).endOf('day')).contains(target)) {
        return 'На завтра'
      } else if (_moment().range(_moment(Today).startOf('day'), _moment(Week).endOf('week')).contains(target)) {
        return 'Эта неделя'
      } else if (_moment().range(_moment(NextWeek).startOf('week'), _moment(NextWeek).endOf('week')).contains(target)) {
        return 'След. неделя'
      } else if (_moment(NextWeek).endOf('week').format('x') < target.format('x')) {
        return 'После след. недели'
      } else {
        return 'Просроченные'
      }
    },
    getRouteOfElementType (elementType) {
      if (elementType === 'Contact') return 'contact'
      if (elementType === 'Company') return 'company'
      if (elementType === 'Lead') return 'trade'
      if (elementType === 'Candidate') return 'candidate'
    },
    getCurrentTaskType (taskType) {
      let currentType = this.taskTypeSelect.filter(type => {
        if (type.value === taskType) return type
      })

      return currentType.length && currentType[0].name ? currentType[0].name : 'Прочее'
    },
    getDateTime (datetime) {
      return datetime ? moment(String(datetime)).format('DD.MM.YYYY HH:mm') : ''
    },
    getDate (datetime) {
      return datetime ? moment(String(datetime)).format('DD.MM.YYYY') : ''
    },
    sortingField (params) {
      if (params[0].type === 'none') {
        this.sort = {
          field: 'createdAt',
          order: 'desc'
        }
        return
      }
      this.sort.order = params[0].type
      this.sort.field = params[0].field
    },
    sortingField2 (columnName) {
      if (columnName === this.sort.field) this.sort.order = this.sort.order === 'asc' ? 'desc' : 'asc'
      this.sort.field = columnName
    },
    getSortableClass (columnName) {
      let currentClass = 'lsg-table-sort'
      if (columnName === this.sort.field) currentClass += ' sort-' + this.sort.order
      return currentClass
    },
    async callPhone (phone) {
      if (!phone || !this.$store.state.me.workphone) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Не указан телефон'
        })
        return
      }
      const callWindow = window.open(`http://192.168.10.3/amocrm/amocrm.php?_login=amocrm&_secret=mWYd1BU9hmWuezov&_action=call&to=${phone}&as=Amocrm&from=${this.$store.state.me.workphone}&rand=0.9550694509986963`)
      setTimeout(() => {
        callWindow.close()
      }, 1_000)
    }
  }
}
</script>
