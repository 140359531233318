<template>
  <div>
    <div class="main__header">
      <div class="main__header__item left">
        <mq-layout :mq="['sm', 'xsm', 'md', 'lg']" class="filter_hide" v-if="!$store.state.data.tasks.isSwipeTasks">
          <span class="btn btn_ico" @click="$store.state.data.tasks.showFilter = !$store.state.data.tasks.showFilter" :class="{'disable': $store.state.data.tasks.showFilter}">
            <FilterIco/>
            Скрыть фильтры
          </span>
        </mq-layout>
        <!--        <mq-layout :mq="['xlg', 'xl' ]" class="filter_hide">-->
        <!--          <div class="small-buttons">-->
        <!--            <a class="btn btn&#45;&#45;small" :class="{ active: callCenter }" href="#" @click.prevent="sortCallCenter()">-->
        <!--              <PhoneIcon/>-->
        <!--            </a>-->
        <!--            <a class="btn btn&#45;&#45;small" :class="{ active: createdBy }" href="#" @click.prevent="sortCreatedBy()">-->
        <!--              <InternetIcon/>-->
        <!--            </a>-->
        <!--          </div>-->
        <!--        </mq-layout>-->
      </div>
      <div class="main__header__item">
        <h1>Задачи</h1>
      </div>
      <div class="main__header__item right" style="z-index: 3">
        <mq-layout :mq="['sm', 'xsm', 'md', 'lg']">
          <SwitcherAlt
            :value="$store.state.data.tasks.isSwipeTasks"
            @updateTable="switchTasks"
          />
        </mq-layout>
      </div>
    </div>
    <div class="main__header main__header--secondary" v-if="$store.state.data.tasks.showFilter">
      <div class="toper__item"></div>
      <div class="toper__item" style="flex-direction: column">
        <div class="button-group" v-if="!$store.state.data.tasks.isSwipeTasks">
          <button type="button" :class="{ active: $store.state.data.tasks.sort2 === 'today' }" @click="setSortType('today')">
            <span>Сегодня</span>
          </button>
          <button type="button" :class="{ active: $store.state.data.tasks.sort2 === 'tomorrow' }" @click="setSortType('tomorrow')">
            <span>Завтра</span>
          </button>
          <button type="button" :class="{ active: $store.state.data.tasks.sort2 === 'week' }" @click="setSortType('week')">
            <span>Неделя</span>
          </button>
          <button type="button" :class="{ active: $store.state.data.tasks.sort2 === 'month' }" @click="setSortType('month')">
            <span>Месяц</span>
          </button>
          <button type="button" @click="openPeriod = true" :class="{ active: $store.state.data.tasks.sort2 === 'period' && $store.state.data.tasks.sortDate }" ref="buttonPeriod" v-click-outside="vcoConfig">
            <i>
              <CalendarIcon/>
            </i>
            <span>Период</span>
            <b class="date-picker" v-show="openPeriod">
              <date-picker v-model="$store.state.data.tasks.sortDate" ref="pickerPeriod" inline range/>
            </b>
          </button>
        </div>
      </div>
      <div class="toper__item"></div>
    </div>
    <!-- <TasksDoCards /> -->
    <div class="main__content">
      <!-- <mq-layout :mq="['sm', 'xsm', 'md', 'lg']" class="filter_hide">
        <tasks-do-cards >
      </mq-layout> -->
      <tasks-kanban v-if="isKanbanView" ref="kanban" :callCenter="callCenter" :createdBy="createdBy"/>
      <tasks-table v-else-if="isTableView" ref="table" :callCenter="callCenter" :createdBy="createdBy"/>
      <tasks-do v-else ref="tasksdo" />
    </div>
  </div>
</template>

<script>
import TasksKanban from './components/TasksKanban'
import TasksTable from './components/TasksTable'
import TasksDo from './components/TasksDo'
import CalendarIcon from '@/components/svg/CalendarIcon'
// import PlusIco from '@/components/svg/plus'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import FilterIco from '@/components/svg/new/Filter'
// import CardsComponent from '@/components/ui/SwipeCards/CardsComponent'
import SwitcherAlt from '@/components/ui/SwitcherAlt'

export default {
  name: 'Tasks',
  data () {
    return {
      isDoView: true,
      openPeriod: false,
      vcoConfig: {
        handler: () => {
          this.openPeriod = false
        }
      },
      callCenter: false,
      createdBy: false
    }
  },
  components: {
    // PlusIco,
    CalendarIcon,
    TasksTable,
    FilterIco,
    TasksKanban,
    DatePicker,
    SwitcherAlt,
    TasksDo
    // TasksDoCards: CardsComponent
  },
  computed: {
    isTableView () {
      return this.$store.state.data.tasks.view === 'table'
    },
    isKanbanView () {
      return this.$store.state.data.tasks.view === 'kanban'
    }
  },
  watch: {
    '$store.state.data.tasks.sortDate' () {
      if (this.$store.state.data.tasks.sortDate) {
        this.$store.state.data.tasks.sort2 = 'period'
      } else {
        this.$store.state.data.tasks.sort2 = null
      }
      this.openPeriod = false
    },
    '$store.state.data.tasks.view' () {
      window.location.hash = this.$store.state.data.tasks.view
    }
  },
  methods: {
    switchTasks (value) {
      this.$store.state.data.tasks.isSwipeTasks = value
    },
    sortCallCenter () {
      this.callCenter = !this.callCenter
    },
    sortCreatedBy () {
      this.createdBy = !this.createdBy
    },
    setSortType (type) {
      if (this.$store.state.data.tasks.sort2 === type) {
        this.$store.state.data.tasks.sort2 = null
      } else {
        this.$store.state.data.tasks.sort2 = type
      }
    }
  },
  created () {
    if (window.location.hash === '') {
      this.$store.state.data.tasks.view = 'table'
      window.location.hash = this.$store.state.data.tasks.view
    } else {
      this.$store.state.data.tasks.view = window.location.hash.substr(1)
      if (this.$store.state.data.tasks.view === 'tableDo') {
        this.$store.state.data.tasks.showFilter = false
      }
    }
  }
}
</script>

<style lang="stylus">
  .swipe-setting {
    display: flex;
    flex-direction: row;
  }
</style>
