<template>
  <div class="cards" :class="{ 'not-empty': cards.length }">
    <Card
      v-for="(card, index) in cards"
      :key="card.id"
      :card="card"
      :is-current="index === 0"
      @cardAccepted="$emit('cardAccepted', card,)"
      @cardRejected="$emit('cardRejected', card)"
      @cardSkipped="$emit('cardSkipped', card)"
      @hideCard="$emit('hideCard', card)"
      @cardAcceptWithComments="$emit('cardAcceptWithComments', card)"
    />
    <Card
      :card="{
        emptyCard: true
      }"
      :is-current="false"
    />
  </div>
</template>

<script>

import Card from './Card'

export default {
  components: {
    Card
  },

  props: {
    cards: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.cards, .not-empty {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
//   margin: 50px;
  width: 100%;
  height: 60vh;
}

.not-empty:before {
  content: '';
    // border-radius: 10px;
    position: fixed;
    top: 60px;
    bottom: 60px;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: calc(100% - 60px);
    z-index: 0;
    // max-height: 60vh;
    // max-width: 300px;
    box-shadow: -67px 0px 100px -98px rgba(4, 255, 0, 1) inset, 67px 0px 100px -98px #ff8000 inset;
  }
</style>
