<template>
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="m5.34365,5.94199l0,12.01718l-3.26679,0l0,-8.01146l-2.17786,0l0,-3.0043l2.17786,0l0,-1.00143l3.26679,0zm10.6715,5.00716l3.37568,0c-0.54446,-2.80401 -3.15789,-5.00716 -6.42468,-5.00716c-3.59346,0 -6.53357,2.70386 -6.53357,6.00859c0,3.23768 2.82212,5.90193 6.31578,6.00859l11.10707,0l0,-3.0043l-10.88928,0c-3.10089,-0.06389 -4.13276,-3.20203 -2.4832,-4.97615c1.63219,-1.74302 4.71778,-1.1905 5.54073,0.99215"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'TypeCone'
}
</script>
