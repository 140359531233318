<template>
  <ApolloQuery :query="require('@/graphql/queries/AllTasks.gql')"
               :variables="{ filter: getTasksFilter, sortField: sort.field, sortOrder: sort.order }"
               @result="doneLoadingTasks">
    <template slot-scope="{ result: { error }, isLoading }">
      <div class="messages apollo" v-if="error">
        <div class="messages__status error" v-if="error">Ошибка загрузки данных. Обратитесь в тех. поддержку</div>
      </div>
      <div v-if="isLoading">
        Загрузка
      </div>
      <div class="tbl tbl--divided">
        <div class="tbl__header">
          <div class="tbl__filter">
            <label class="tbl__filter__item">
              <v-select appendToBody :options="$store.state.allUsers.map(item => ({ value: item.id, label: item.name }))" @input="onUserChange" placeholder="Выберите менеджера">
                <template slot="no-options">Нет результатов</template>
                <template #open-indicator="{ attributes }">
                  <div v-bind="attributes">
                    <DownIcon/>
                  </div>
                </template>
              </v-select>
            </label>
            <label class="tbl__filter__item">
              <v-select appendToBody :options="$store.state.allGroups.map(item => ({ value: item.id, label: item.name }))" @input="onGroupChange" multiple placeholder="Выберите подразделения">
                <template slot="no-options">Нет результатов</template>
                <template #open-indicator="{ attributes }">
                  <div v-bind="attributes">
                    <DownIcon/>
                  </div>
                </template>
              </v-select>
            </label>
          </div>
          <div class="tbl__settings">
            <mq-layout :mq="[ 'lg', 'xlg', 'xl' ]">
              <a class="tbl__setting" href="javascript:void(0)">
                <SettingsIcon/>
              </a>
            </mq-layout>
            <div class="tbl__type">
              <a :class="{ active: $store.state.data.tasks.view === 'tableDo'}" @click.prevent="$store.state.data.tasks.view = 'tableDo'" href="#">
                <TypeCone/>
              </a>
              <a :class="{ active: $store.state.data.tasks.view === 'table'}" @click.prevent="$store.state.data.tasks.view = 'table'" href="#">
                <TypeTable/>
              </a>
              <a :class="{ active: $store.state.data.tasks.view === 'kanban'}" @click.prevent="$store.state.data.tasks.view = 'kanban'" href="#">
                <TypeKanban/>
              </a>
            </div>
          </div>
        </div>
        <div class="tbl__container">
          <!--          чтобы скрыть столбцы без задач-->
          <!--          stages.filter(stage => $store.state.allTasks.filter(item => item.status === stage).length)-->
          <kanban-board :blocks="$store.state.allTasks" :stages="$store.state.loadMoreTasks ? stages_all : stages" @update-block="update" class="kanban-wrapper" id="tasks-kanban" v-if="!isLoading">
            <div :key="stage" :slot="stage" class="kanban-header" v-for="stage in ($store.state.loadMoreTasks ? stages_all : stages)">
              <h3>{{ stage }}</h3>
              <div class="kanban-header-info">
                {{ getTasksCountForBoard(stage) }} задач
              </div>
            </div>
            <router-link :key="block.id" :slot="block.id" :to="{name: getRouteOfElementType(block.element_type), params: {id: block.element.id }}" class="tasks-kanban" v-for="block in $store.state.allTasks">
              <div class="tasks-kanban__header" :class="{ 'tasks-kanban__header--red': block.status === 'Просроченные'}">
                <span class="tasks-kanban__header__status">{{ getCurrentTaskType(block.task_type) }}</span>
                <div class="tasks-kanban__header__item">
                  <span class="tasks-kanban__header__item__title">До:</span>
                  <div class="tasks-kanban__header__item__text">
                    <i>
                      <ClockCircle/>
                    </i>
                    <span>{{ getDate(block.complete_till_at) }}</span>
                  </div>
                </div>
              </div>
              <div class="tasks-kanban__body">
                <div class="tasks-kanban__item" v-if="block.element && block.element.name ">
                  <span class="tasks-kanban__item__title">Компания:</span>
                  <a class="tasks-kanban__item__text" href="#">{{ block.element.name }}</a>
                </div>
                <div class="tasks-kanban__item">
                  <span class="tasks-kanban__item__title">Комментарий:</span>
                  <span class="tasks-kanban__item__text">{{ block.text }}</span>
                </div>
              </div>
            </router-link>
          </kanban-board>
        </div>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>
import moment from 'moment'
import SettingsIcon from '@/components/svg/settings'
import TypeTable from '@/components/svg/TypeTable'
import TypeCone from '@/components/svg/TypeCone'
import TypeKanban from '@/components/svg/TypeKanban'
import ClockCircle from '@/components/svg/ClockCircle'
import DownIcon from '@/components/svg/DownIcon'
import { extendMoment } from 'moment-range'

moment.updateLocale('ru', {
  week: {
    dow: 1
  }
})

const _moment = extendMoment(moment)
const Today = _moment()
const Tomorrow = _moment().add(1, 'days')
const Week = _moment().endOf('week')
const NextWeek = _moment().endOf('week').add(1, 'week')

export default {
  name: 'TasksKanban',
  components: { DownIcon, ClockCircle, TypeKanban, TypeTable, TypeCone, SettingsIcon },
  props: {
    callCenter: {
      type: Boolean,
      required: true
    },
    createdBy: {
      type: Boolean,
      required: true
    }
  },
  created () {
    this.filter.responsible_user_id = this.$store.state.me._id
  },
  data () {
    return {
      stages: ['Просроченные', 'На сегодня', 'На завтра', 'Эта неделя', 'След. неделя'
        // 'После след. недели'
      ],
      stages_all: ['Завершенные', 'Просроченные', 'На сегодня', 'На завтра', 'Эта неделя', 'След. неделя'
        // 'После след. недели'
      ],
      loadMore: false,
      sort: {
        field: 'createdAt',
        order: 'desc'
      },
      tasks: [],
      filter: {
        q: '',
        complete_till_at: null,
        responsible_user_id: null,
        status: null,
        group_id: [],
        is_completed: false,
        call_center: false
      },
      // eslint-disable-next-line vue/no-reserved-keys
      _allTasksMeta: 0,
      taskTypeSelect: [
        {
          value: 'Call',
          name: 'Звонок'
        }, {
          value: 'Meeting',
          name: 'Переговоры'
        }, {
          value: 'WriteEmail',
          name: 'Написать письмо'
        }, {
          value: 'Get_docs',
          name: 'Получить документы'
        }, {
          value: 'Action',
          name: 'Прочее'
        }
      ]
    }
  },
  computed: {
    getTasksFilter () {
      let currentFilter = {}
      if (this.filter.q) currentFilter.q = this.filter.q
      if (this.filter.complete_till_at) currentFilter.complete_till_at = this.filter.complete_till_at
      if (this.filter.responsible_user_id) currentFilter.responsible_user_id = this.filter.responsible_user_id
      if (this.filter.group_id.length) currentFilter.group_id = this.filter.group_id
      if (this.filter.is_completed !== null) currentFilter.is_completed = this.filter.is_completed
      // if (this.filter.call_center) currentFilter.callCenter = this.filter.call_center
      return currentFilter
    }
  },
  watch: {
    '$store.state.data.tasks.sort2' () {
      this.filter.status = this.$store.state.data.tasks.sort2
      this.onStatusChange()
    },
    '$store.state.data.tasks.sortDate' () {
      this.filter.status = this.$store.state.data.tasks.sort2
      this.onStatusChange()
    },
    '$store.state.loadMoreTasks' () {
      if (this.$store.state.loadMoreTasks) {
        this.filter.is_completed = null
      }
    }
  },
  methods: {
    getRange (status) {
      switch (status) {
        case 'today':
          return {
            start: _moment(Today).startOf('day').format('YYYY-MM-DD'),
            end: _moment(Today).startOf('day').add(1, 'days').format('YYYY-MM-DD')
          }
        case 'tomorrow':
          return {
            start: _moment(Tomorrow).startOf('day').format('YYYY-MM-DD'),
            end: _moment(Tomorrow).startOf('day').add(1, 'days').format('YYYY-MM-DD')
          }
        case 'week':
          return {
            start: _moment(Today).startOf('day').format('YYYY-MM-DD'),
            end: _moment(Week).endOf('week').add(1, 'days').format('YYYY-MM-DD')
          }
        case 'next_week':
          return {
            start: _moment(NextWeek).startOf('week').format('YYYY-MM-DD'),
            end: _moment(NextWeek).endOf('week').add(1, 'days').format('YYYY-MM-DD')
          }
        case 'month':
          return {
            start: _moment(Today).startOf('month').format('YYYY-MM-DD'),
            end: _moment(Today).endOf('month').add(1, 'days').format('YYYY-MM-DD')
          }
        case 'after_next_week':
          return {
            start: _moment(NextWeek).endOf('week').format('YYYY-MM-DD')
          }
        case 'expired':
          return {
            end: _moment(Today).startOf('day').format('YYYY-MM-DD')
          }
        case 'period':
          return {
            start: _moment(this.$store.state.data.tasks.sortDate[0]).startOf('day').format('YYYY-MM-DD'),
            end: _moment(this.$store.state.data.tasks.sortDate[1]).endOf('day').format('YYYY-MM-DD')
          }
        default:
          return null
      }
    },
    onStatusChange () {
      switch (this.filter.status) {
        case null:
          this.filter.complete_till_at = null
          this.filter.is_completed = null
          break
        case 'uncompleted':
          this.filter.complete_till_at = null
          this.filter.is_completed = false
          break
        case 'completed':
          this.filter.complete_till_at = null
          this.filter.is_completed = true
          break
        default:
          this.filter.complete_till_at = this.getRange(this.filter.status)
          this.filter.is_completed = false
          break
      }
    },
    onGroupChange (e) {
      this.filter.group_id = e.map(item => item.value)
    },
    onUserChange (value) {
      if (value) {
        this.filter.responsible_user_id = value.value
        const user = this.$store.state.allUsers.filter(item => item.id === value.value)
        this.filter.group_id = user[0] ? user[0].group.id : null
      } else {
        this.filter.responsible_user_id = this.$store.state.me._id
        this.filter.group_id = this.$store.state.me.group.id
      }
    },
    getDate (datetime) {
      return datetime ? moment(String(datetime)).format('DD.MM.YYYY HH:MM') : ''
    },
    getStatusBasedDate (status) {
      switch (status) {
        case 'Завершенные':
          return moment().format('YYYY-MM-DDTHH:mm')
        case 'Просроченные':
          return moment().subtract(1, 'days').format('YYYY-MM-DDTHH:mm')
        // case 'После след. недели':
        //   return moment().add(2, 'weeks').format('YYYY-MM-DDTHH:mm')
        case 'След. неделя':
          return moment().add(1, 'weeks').format('YYYY-MM-DDTHH:mm')
        case 'Эта неделя':
          return moment().add(2, 'days').format('YYYY-MM-DDTHH:mm')
        case 'На завтра':
          return moment().add(1, 'days').format('YYYY-MM-DDTHH:mm')
        case 'На сегодня':
          return moment().startOf('day').format('YYYY-MM-DDTHH:mm')
      }
    },
    update (id, status) {
      let taskType = this.$store.state.allTasks.find(item => item.id === id).task_type
      if (status === 'Завершенные') {
        this.$store.dispatch('updateTask', {
          context: this,
          data: {
            id: id,
            is_completed: true,
            complete_till_at: this.getStatusBasedDate(status),
            task_type: taskType
          }
        })
      } else {
        this.$store.dispatch('updateTask', {
          context: this,
          data: {
            id: id,
            is_completed: false,
            complete_till_at: this.getStatusBasedDate(status),
            task_type: taskType
          }
        })
      }
    },
    doneLoadingTasks ({ data }) {
      this.$store.commit('allTasks', { data, isMore: !this.$store.state.allTasks.length })
    },
    getTasksCountForBoard (stage) {
      let count = 0
      this.$store.state.allTasks.forEach(item => {
        if (item.status === stage) {
          count++
        }
      })
      return count
    },
    getRouteOfElementType (elementType) {
      if (elementType === 'Contact') return 'contact'
      if (elementType === 'Company') return 'company'
      if (elementType === 'Lead') return 'trade'
      if (elementType === 'Candidate') return 'candidate'
    },
    getCurrentTaskType (taskType) {
      let currentType = this.taskTypeSelect.filter(type => {
        if (type.value === taskType) return type
      })

      return currentType.length && currentType[0].name ? currentType[0].name : 'Прочее'
    }
  }
}
</script>
