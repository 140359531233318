var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isShowing)?_c('div',{ref:"interactElement",staticClass:"card",class:{
    isAnimating: _vm.isInteractAnimating,
    isCurrent: _vm.isCurrent,
    isEmpty: _vm.card.emptyCard
  },style:({ transform: _vm.transformString, 'background': _vm.backgroundColorString })},[(_vm.card.emptyCard)?[_c('h3',{staticClass:"cardTitle"},[_vm._v("На данный момент у вас нет непросмотренных задач")]),_c('div',{staticClass:"cardInfo"},[_vm._v("Чтобы увидеть пропущенные задачи, перезагрузите страницу")])]:[(_vm.step === 1)?_c('div',{staticClass:"cardMainInfo"},[_c('h3',{staticClass:"cardTitle"},[_vm._v(_vm._s(_vm.card.type_doc))]),_c('div',{staticClass:"cardInfo text-hidden"},[_vm._v(_vm._s(_vm.card.fullname))]),_c('div',{staticClass:"cardComment text-hidden"},[_vm._v(_vm._s(_vm.card.comment))]),(_vm.card.type_doc === 'Реестр платежей')?_c('div',[_c('span',{staticClass:"modal__attention__title"},[_vm._v("Реестр платежей")]),_c('ul',[_c('li',[_c('a',{ref:"interactElementRegistry",staticClass:"modal__attention__files"},[_vm._v("Скачать")])])])]):_vm._e()]):_vm._e(),(_vm.visas.length && _vm.step === 1)?_c('div',{staticClass:"cardVisas"},_vm._l((_vm.visas),function(visa,index){return _c('div',{key:visa.autor + index,staticClass:"cardVisas__container text-hidden"},[_c('div',{staticClass:"cardVisas__autor"},[_vm._v(_vm._s(_vm.shortName(visa.autor)))]),_c('div',{staticClass:"cardVisas__status tag",class:{
            'tag--green': visa.result === 'Согласовано',
            'tag--yellow': visa.result === 'СогласованоСЗамечаниями',
            'tag--red': visa.result === 'НеСогласовано'
          }},[_vm._v(_vm._s(visa.result === 'СогласованоСЗамечаниями' ? 'Замечания' : visa.result || 'ожидание'))]),_c('div',{staticClass:"cardVisas__comment"},[_vm._v(_vm._s(visa.comment))])])}),0):_vm._e(),(_vm.step === 2)?_c('div',{staticClass:"cardHistory"},[(_vm.card.history)?_c('h3',{staticClass:"cardTitle"},[_vm._v("История")]):_vm._e(),_c('div',{staticClass:"cardHistory text-hidden"},[_vm._v(_vm._s(_vm.card.history))]),(_vm.docs.length)?_c('div',{ref:"interactElementDocs",staticClass:"btn cardTitle",staticStyle:{"font-size":"1.125em"}},[_vm._v("Документы")]):_vm._e(),(_vm.showDocs)?_c('div',{staticClass:"modal cardDocs"},[_c('div',{staticClass:"modal__body"},[_c('h3',{staticClass:"cardTitle"},[_vm._v("Документы")]),_c('ul',_vm._l((_vm.docs),function(item,index){return _c('li',{key:index},[_c('a',{ref:item.id + 'doc-ref',refInFor:true,staticClass:"cardFiles__item"},[_vm._v(_vm._s(("" + (item.name.length > 20 ? item.name.substr(0, 20) + '_' + item.ext : item.name))))])])}),0),_c('div',{ref:"interactElementDocsClose",staticClass:"btn btn--gray",staticStyle:{"margin-left":"auto","font-size":"1.125em"}},[_vm._v("Закрыть")])])]):_vm._e()]):_vm._e(),_c('div',{staticClass:"cardBottom"},[_c('div',{staticClass:"cardComment"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.comment),expression:"comment"}],attrs:{"type":"text"},domProps:{"value":(_vm.comment)},on:{"input":function($event){if($event.target.composing){ return; }_vm.comment=$event.target.value}}})]),_c('div',{staticClass:"cardPagination"},[_c('div',{ref:"interactElementInfo",staticClass:"fullscreen-icon"},[_c('FullScreenIcon')],1),_c('div',[_vm._v(_vm._s(_vm.step)+" / "+_vm._s(_vm.stepCount))]),_c('div',{ref:"interactElementBottom"},[_c('i',{staticClass:"arrow right"})])])])]],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }