<template>
  <div
    v-if="isShowing"
    ref="interactElement"
    :class="{
      isAnimating: isInteractAnimating,
      isCurrent: isCurrent,
      isEmpty: card.emptyCard
    }"
    class="card"
    :style="{ transform: transformString, 'background': backgroundColorString }"
  >
    <template v-if="card.emptyCard">
      <h3 class="cardTitle">На данный момент у вас нет непросмотренных задач</h3>
      <div class="cardInfo">Чтобы увидеть пропущенные задачи, перезагрузите страницу</div>
    </template>
    <template v-else>
      <div v-if="step === 1" class="cardMainInfo">
        <h3 class="cardTitle">{{ card.type_doc }}</h3>
        <div class="cardInfo text-hidden">{{ card.fullname }}</div>
        <div class="cardComment text-hidden">{{ card.comment }}</div>
        <div v-if="card.type_doc === 'Реестр платежей'">
          <span class="modal__attention__title">Реестр платежей</span>
          <ul>
            <li>
              <a class="modal__attention__files" ref="interactElementRegistry">Скачать</a>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="visas.length && step === 1" class="cardVisas">
        <!-- <h3 class="cardTitle">Визы</h3> -->
        <div v-for="(visa, index) in visas" :key="visa.autor + index" class="cardVisas__container text-hidden">
          <div class="cardVisas__autor">{{ shortName(visa.autor) }}</div>
          <div class="cardVisas__status tag"
            :class="{
              'tag--green': visa.result === 'Согласовано',
              'tag--yellow': visa.result === 'СогласованоСЗамечаниями',
              'tag--red': visa.result === 'НеСогласовано'
            }"
          >{{ visa.result === 'СогласованоСЗамечаниями' ? 'Замечания' : visa.result || 'ожидание' }}</div>
          <div class="cardVisas__comment">{{ visa.comment }}</div>
        </div>
      </div>
      <div v-if="step === 2" class="cardHistory">
        <h3 class="cardTitle" v-if="card.history">История</h3>
        <div class="cardHistory text-hidden">{{ card.history }}</div>
        <div class="btn cardTitle" v-if="docs.length" style="font-size: 1.125em" ref="interactElementDocs">Документы</div>
        <div class="modal cardDocs" v-if="showDocs">
          <div class="modal__body">
            <h3 class="cardTitle">Документы</h3>
            <ul>
              <li v-for="(item, index) in docs" :key="index"><a class="cardFiles__item" :ref="item.id + 'doc-ref'">{{`${item.name.length > 20 ? item.name.substr(0, 20) + '_' + item.ext : item.name}`}}</a></li>
            </ul>
            <div class="btn btn--gray" ref="interactElementDocsClose" style="margin-left: auto; font-size: 1.125em">Закрыть</div>
          </div>
        </div>
      </div>
      <div class="cardBottom">
        <div class="cardComment">
          <input type="text" v-model="comment">
        </div>
        <div class="cardPagination">
          <div class="fullscreen-icon" ref="interactElementInfo"><FullScreenIcon/></div>
          <div>{{ step }} / {{ stepCount }}</div>
          <div ref="interactElementBottom">
            <i class="arrow right"></i>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import interact from 'interact.js'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import VISAS from '@/graphql/queries/DocVisas.gql'
import axios from 'axios'

import FullScreenIcon from '@/components/svg/new/FullScreen.vue'

const _graphQlHelper = new GraphQLHelper()

const ACCEPT_CARD = 'cardAccepted'
const REJECT_CARD = 'cardRejected'
const SKIP_CARD = 'cardSkipped'
const BASE_URL = process.env.VUE_APP_HTTP

export default {
  static: {
    interactMaxRotation: 15,
    interactOutOfSightXCoordinate: 500,
    interactOutOfSightYCoordinate: 600,
    // interactLockYAxis: true,
    interactYThreshold: 20,
    interactXThreshold: 100
  },

  components: {
    FullScreenIcon
  },

  props: {
    card: {
      type: Object,
      required: true
    },
    isCurrent: {
      type: Boolean,
      required: true
    },
    updateTask: {
      type: Function
    }
  },

  data () {
    return {
      isShowing: true,
      isInteractAnimating: true,
      isInteractDragged: null,
      interactPosition: {
        x: 0,
        y: 0,
        rotation: 0
      },
      interactBackgroundColor: {
        color: {
          r: 255,
          g: 255,
          b: 255
        },
        opacity: 1
      },
      comment: '',
      visas: [],
      step: 1,
      stepCount: 1,
      backgroundColor: '',
      BASE_URL,
      docs: [],
      showDocs: false
    }
  },

  async created () {
    if (this.card.doc !== 'Нет предметов' && !this.card.emptyCard) {
      this.visas = this.getVisas(this.card.doc)
      this.docs = await this.getDocs(this.card.doc)
    }
    this.stepCount = +!!this.card.fullname + +(+!!this.card.history || +!!this.docs.length)

    const element = this.$refs.interactElement

    interact(element).draggable({
      onstart: () => {
        this.isInteractAnimating = false
      },

      onmove: (event) => {
        const {
          interactMaxRotation,
          interactXThreshold
        } = this.$options.static
        const x = this.interactPosition.x + event.dx
        const y = this.interactPosition.y + event.dy

        let rotation = interactMaxRotation * (x / interactXThreshold)

        if (rotation > interactMaxRotation) rotation = interactMaxRotation
        else if (rotation < -interactMaxRotation) rotation = -interactMaxRotation

        if (x > 0) {
          this.interactBackgroundColor = {
            color: {
              r: 4,
              g: 255,
              b: 0
            },
            opacity: 1
          }
        }

        if (x < 0) {
          this.interactBackgroundColor = {
            color: {
              r: 193,
              g: 50,
              b: 20
            },
            opacity: 1
          }
        }

        this.interactSetPosition({ x, y, rotation })
      },

      onend: () => {
        const { x, y } = this.interactPosition
        const { interactXThreshold, interactYThreshold } = this.$options.static
        this.isInteractAnimating = true

        if (x > interactXThreshold) this.playCard(ACCEPT_CARD)
        else if (x < -interactXThreshold) this.playCard(REJECT_CARD)
        else if (y > interactYThreshold) this.playCard(SKIP_CARD) // с замечаниями
        else if (y < -interactYThreshold) this.playCard(SKIP_CARD)
        else this.resetCardPosition()
      }
    })

    const elementBottom = this.$refs.interactElementBottom

    interact(elementBottom).on('tap', () => {
      this.nextStep()
    })

    const elementInfo = this.$refs.interactElementInfo

    interact(elementInfo).on('tap', () => {
      this.showInfoModal()
    })

    const elementRegistry = this.$refs.interactElementRegistry

    if (elementRegistry) {
      interact(elementRegistry).on('tap', () => {
        this.downloadDoc(this.card.id_doc)
      })
    }

    if (this.docs) {
      const elementDocs = this.$refs.interactElementDocs
      interact(elementDocs).on('tap', () => {
        this.toggleDocs()
      })

      const elementDocsClose = this.$refs.interactElementDocsClose
      interact(elementDocsClose).on('tap', () => {
        this.toggleDocs()
      })

      this.docs.forEach(elem => {
        const refName = `${elem.id}doc-ref`
        const elementDoc = this.$refs[refName]
        if (elementDoc) {
          interact(elementDoc[0]).on('tap', () => {
            this.downloadDocument(elem.id)
          })
        }
      })
    }
  },

  computed: {
    transformString () {
      if (!this.isInteractAnimating || this.isInteractDragged) {
        const { x, y, rotation } = this.interactPosition
        return `translate3D(${x}px, ${y}px, 0) rotate(${rotation}deg)`
      }

      return null
    },
    backgroundColorString () {
      if (!this.isInteractAnimating || this.isInteractDragged) {
        const { color: { r, g, b }, opacity } = this.interactBackgroundColor
        const backColor = `rgba(${r}, ${g}, ${b}, ${opacity / 10})`
        return backColor
      }

      return null
    }
  },

  updated () {
    // if (this.docs) {
    //   const elementDocs = this.$refs.interactElementDocs
    //   interact(elementDocs).on('tap', () => {
    //     this.toggleDocs()
    //   })

    //   const elementDocsClose = this.$refs.interactElementDocsClose
    //   interact(elementDocsClose).on('tap', () => {
    //     this.toggleDocs()
    //   })

    //   this.docs.forEach(elem => {
    //     const refName = `${elem.id}doc-ref`
    //     const elementDoc = this.$refs[refName]
    //     if (elementDoc) {
    //       interact(elementDoc[0]).on('tap', () => {
    //         this.downloadDocument(elem.id)
    //       })
    //     }
    //   })
    // }
  },

  watch: {
    step: function (newValue, oldValue) {
      if (newValue > this.stepCount || newValue === 0) this.step = 1
      if (newValue < 1) this.step = this.stepCount
    },
    comment: function (newValue, oldValue) {
      this.$store.state.data.tasks.mobileModal.newComment = newValue
    }
  },

  // mounted () {
  //   const element = this.$refs.interactElement

  //   interact(element).draggable({
  //     onstart: () => {
  //       this.isInteractAnimating = false
  //     },

  //     onmove: (event) => {
  //       const {
  //         interactMaxRotation,
  //         interactXThreshold
  //       } = this.$options.static
  //       const x = this.interactPosition.x + event.dx
  //       const y = this.interactPosition.y + event.dy

  //       let rotation = interactMaxRotation * (x / interactXThreshold)

  //       if (rotation > interactMaxRotation) rotation = interactMaxRotation
  //       else if (rotation < -interactMaxRotation) rotation = -interactMaxRotation

  //       if (x > 0) {
  //         this.interactBackgroundColor = {
  //           color: {
  //             r: 4,
  //             g: 255,
  //             b: 0
  //           },
  //           opacity: 1
  //         }
  //       }

  //       if (x < 0) {
  //         this.interactBackgroundColor = {
  //           color: {
  //             r: 193,
  //             g: 50,
  //             b: 20
  //           },
  //           opacity: 1
  //         }
  //       }

  //       this.interactSetPosition({ x, y, rotation })
  //     },

  //     onend: () => {
  //       const { x, y } = this.interactPosition
  //       const { interactXThreshold, interactYThreshold } = this.$options.static
  //       this.isInteractAnimating = true

  //       if (x > interactXThreshold) this.playCard(ACCEPT_CARD)
  //       else if (x < -interactXThreshold) this.playCard(REJECT_CARD)
  //       else if (y > interactYThreshold) this.playCard(SKIP_CARD) // с замечаниями
  //       else if (y < -interactYThreshold) this.playCard(SKIP_CARD)
  //       else this.resetCardPosition()
  //     }
  //   })

  //   const elementBottom = this.$refs.interactElementBottom

  //   interact(elementBottom).on('tap', () => {
  //     this.nextStep()
  //   })

  //   const elementInfo = this.$refs.interactElementInfo

  //   interact(elementInfo).on('tap', () => {
  //     this.showInfoModal()
  //     console.log('tap')
  //   })

  //   const elementRegistry = this.$refs.interactElementRegistry

  //   if (elementRegistry) {
  //     interact(elementRegistry).on('tap', () => {
  //       this.downloadDoc(this.card.id_doc)
  //     })
  //   }

  //   // this.stepCount = +!!this.card.fullname + +(+!!this.card.history || +!!this.docs ? this.docs.length : 0)
  //   // console.log(+!!this.card.fullname, +!!this.card.history, +!!this.docs.length)
  //   // this.stepCount = +!!this.card.fullname + +(this.card.doc !== 'Нет предметов') + +!!this.card.history
  // },

  beforeDestroy () {
    interact(this.$refs.interactElement).unset()
  },

  methods: {
    showInfoModal () {
      this.$store.state.data.tasks.mobileModal.showInfo = true
      this.$store.state.data.tasks.mobileModal.data = {
        ...this.card,
        visas: this.visas,
        docs: this.docs
      }
    },
    toggleDocs () {
      this.showDocs = !this.showDocs
      // if (this.showDocs) {
      //   this.isInteractAnimating = this.isShowing = false
      // }
    },
    downloadDocument (id) {
      window.open(`${BASE_URL}/file?id=${id}`, '_blank')
    },
    async getDocs (id) {
      const docs = await axios.get(`${BASE_URL}/doc?uid=${id}`)
      return docs.data
    },
    async downloadDoc (id) {
      window.open(`${BASE_URL}/download?id=${id}&type=registry`, '_blank')
    },
    nextStep () {
      this.step++
    },
    prevStep () {
      this.step--
    },
    shortName (name) {
      const splitName = name.split(' ')
      return `${splitName[0]} ${splitName[1] ? splitName[1][0] : ''}.${splitName[2] ? splitName[2][0] : ''}.`
    },
    async getVisas (doc) {
      let visas = []
      this.$apollo
        .mutate({
          mutation: VISAS,
          variables: {
            doc
          },
          update: (_, { data }) => {
            if (data && data.docVisas.length > 0) {
              this.visas = [...data.docVisas]
            }
          }
        })
        .catch((error) => {
          _graphQlHelper.graphQLErrorMessages(error).forEach((item) => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
      return visas
    },

    hideCard () {
      setTimeout(() => {
        this.isShowing = false
        this.$emit('hideCard', this.card)
      }, 300)
    },

    playCard (interaction) {
      const {
        interactOutOfSightXCoordinate,
        interactOutOfSightYCoordinate,
        interactMaxRotation
      } = this.$options.static

      // this.interactUnsetElement()

      this.$store.state.data.tasks.mobileModal.newComment = this.comment

      switch (interaction) {
        case ACCEPT_CARD:
          this.interactSetPosition({
            x: interactOutOfSightXCoordinate,
            rotation: interactMaxRotation
          })
          this.$emit(ACCEPT_CARD)
          break
        case REJECT_CARD:
          this.interactSetPosition({
            x: -interactOutOfSightXCoordinate,
            rotation: -interactMaxRotation
          })
          this.$emit(REJECT_CARD)
          break
        case SKIP_CARD:
          this.interactSetPosition({
            y: interactOutOfSightYCoordinate
          })
          this.$emit(SKIP_CARD)
          break
      }

      if (this.$store.state.data.tasks.mobileModal.newComment || interaction === SKIP_CARD) this.hideCard()
      else this.resetCardPosition()
    },

    interactSetPosition (coordinates) {
      const { x = 0, y = 0, rotation = 0 } = coordinates
      this.interactPosition = { x, y, rotation }
    },

    interactUnsetElement () {
      interact(this.$refs.interactElement).unset()
      this.isInteractDragged = true
    },

    resetCardPosition () {
      this.interactSetPosition({ x: 0, y: 0, rotation: 0 })
      this.interactBackgroundColor = {
        color: {
          r: 255,
          g: 255,
          b: 255
        },
        opacity: 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/index.scss";

$cardsTotal: 3;
$cardsWidth: 300px;
$cardsPositionOffset: 55vh * 0.06;
$cardsScaleOffset: 0.08;
$defaultTranslation: $cardsPositionOffset * $cardsTotal;
$defaultScale: 1 - ($cardsScaleOffset * $cardsTotal);
$fs-card-title: 1.125em;
$fs-card-info: 0.9em;
$fs-card-comment: 0.7em;

.card {
  @include card();
  @include absolute(0);
  @include sizing(100% 80vw);
  @include flex-center();

  @include after() {
    @include sizing(21px 3px);
    @include absolute(right 0 bottom 11px left 0);

    margin: auto;
    border-radius: 100px;
    background: rgba($c-black, 0.3);
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // scroll-snap-type: y mandatory;
  padding: 20px 20px;
  max-height: 60vh;
  max-width: 300px;
  margin: auto;
  font-size: $fs-h2;
  font-weight: $fw-bold;
  color: $c-black;
  background-color: $c-white;
  box-shadow: 0 10px 30px rgba($c-black,.17);
  // background-image: linear-gradient(
  //   -180deg,
  //   $primary-gradient-start 2%,
  //   $primary-gradient-end 100%
  // );
  opacity: 0;
  transform: translateY($defaultTranslation) scale($defaultScale);
  transform-origin: 50%, 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  user-select: none;
  pointer-events: none;
  will-change: transform, opacity;

  height: 100%;

  &.isCurrent {
    pointer-events: auto;
  }

  &.isAnimating {
    transition: transform 0.7s $ease-out-back;
  }

  &.isEmpty {
    box-shadow: 0 10px 10px rgba($c-black,.17) inset;
  }
}

.arrow {
  border: solid rgba($c-black, .5);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
}

// .cardMainInfo {
//   // height: 100%;
// }

.text-hidden {
  text-overflow: ellipsis;
  overflow: hidden;
}

.text-hidden:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 1.2em;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white 100%);
  pointer-events: none;
}

.cardFiles__item {
  cursor: pointer;
  color: #FF8000;
  z-index: 3;
}

.cardMainInfo {
  max-height: 30%;
}

.fullscreen-icon {
  // padding: 5px;
  height: 12px;
  width: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen-icon > svg {
  width: 100%;
  height: 100%;
  stroke: rgba($c-black, 0.5);
  fill: rgba($c-black, 0.5);
}

.cardPagination {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.cardDocs {
  position: fixed;
  top: 0;
  right: 0;
  // min-height: 90vh;
  // width: 90vw;
}

.cardDocs > .modal__body {
  margin: 0;
  width: inherit;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.cardDocs > .modal__body > h3 {
  width: 100%;
  text-align: center;
}

.cardDocs > .modal__body > ul {
  height: 100%;
}

.cardComment {
  width: 100%;
}

.cardHistory {
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: 100%;
}

.cardInfo {
  margin: 0 0 15px;
  font-size: $fs-card-info;
  // color: darkgrey;
  text-align: center;
}

.cardHistory {
  margin: 0 0 15px;
  font-size: $fs-card-info;
  // color: darkgrey;
  text-align: start;
  max-height: 30%;
}

.cardTitle {
  margin: 0 0 15px;
  font-size: $fs-card-title;
  // color: darkgrey;
  text-align: center;
  scroll-snap-align: start;
}

.cardComment {
  margin: 0 0 15px;
  font-size: $fs-card-title;
  // color: darkgrey;
  text-align: center;
  font-size: $fs-card-comment;
  max-height: 30%;
}

.cardVisas {
  display: flex;
  flex-direction: column;
  // height: 100%;
  width: 100%;
  // scroll-snap-align: end;
  max-height: 40%;
}

.cardVisas__container {
  display: grid;
  // grid-auto-rows: minmax(100px, auto);
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-gap: 10px;
  grid-template-areas:
    "autor status"
    "comment comment";
}

.cardVisas__autor {
  grid-area: autor;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.cardVisas__status {
  grid-area: status;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cardVisas__comment {
  grid-area: comment;
  display: flex;
  padding: auto 10px;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: $fs-card-comment;
  max-height: 30%;
}

.cardBottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@for $i from 1 through $cardsTotal {
  $index: $i - 1;
  $translation: $cardsPositionOffset * $index;
  $scale: 1 - ($cardsScaleOffset * $index);

  .card:nth-child(#{$i}) {
    z-index: $cardsTotal - $index;
    opacity: 1;
    transform: translateY($translation) scale($scale);

    @if $i == 3 {
      color: $c-red-25;
      background-color: $c-red-25;
    } @else if $i == 2 {
      color: $c-red-50;
      background-color: $c-red-50;
    } @else if $i == $cardsTotal - 1 {
      color: $c-red-50;
      background-color: $c-black;
    }

    @if $i != 1 {
      background-image: none;

      @include after() {
        @include sizing(0 0);
      }
    }
  }
}
</style>
