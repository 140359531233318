<template>
  <div>
    <div class="modal" style="align-items: flex-start" v-if="showModal">
      <div class="modal__back" @click="showModal = false"></div>
      <div class="modal__body modal__body--task-do">
        <div class="modal__attention" style="white-space: pre-wrap; overflow-y: auto">
          <span class="modal__attention__title">{{ selectedRow.name || selectedRow.fullname }}</span>
          <!-- <span v-if="selectedRow.comment" class="modal__attention__title" style="color:darkgrey;">{{selectedRow.comment}}</span> -->
          <div v-if="selectedRow.path_dot==='Согласовать' || selectedRow.path_dot==='Исполнить' || 'Проверить исполнение'" style="margin:5px;width:70%;">
            <label style="color:grey;">Комментарий:</label>
            <input type="text" v-model="message" style="margin:3px;" >
          </div>
          <span class="modal__attention__title" style="color:darkgrey;">{{content}}</span>
          <!-- <div v-if="selectedRow.text !== '' || selectedRow.history !== ''" @click="() => subtitleExpanded = !subtitleExpanded"><a style="cursor: pointer">{{ subtitleExpanded? 'Свернуть': 'Развернуть'}}</a></div> -->
          <div class="modal__attention__container">
            <div v-if="history.length > 0" class="modal__attention__table">
              <vue-good-table
                :columns="columnsAgreement"
                :rows="history"
                compactMode
              >
                <template slot="table-row" slot-scope="props">
                  <div v-if="props.column.field === 'autor'"><div>{{ props.row.autor || ' ' }}</div></div>
                  <div v-if="props.column.field === 'result' && props.row.result === 'Согласовано'" class="tag tag--green tag--task">{{ props.row.result }}</div>
                  <div v-else-if="props.column.field === 'result' && props.row.result === 'СогласованоСЗамечаниями'" class="tag tag--yellow tag--task">Согласовано с замечаниями</div>
                  <div v-else-if="props.column.field === 'result' && props.row.result === 'НеСогласовано'" class="tag tag--red tag--task"><div>Не согласовано</div></div>
                  <div v-else-if="props.column.field === 'result' && props.row.result === ''"><div>-</div></div>
                  <div v-if="props.column.field === 'comment'"><div>{{ props.row.comment || '-' }}</div></div>
                </template>
              </vue-good-table>
            </div>
            <div v-if="selectedRow.history" @click="openHistory = !openHistory" class="trade__modal__header" style="cursor: pointer">
              <span class="modal__attention__title">История</span>
              <div class="history__icon">
                <svg v-if="!openHistory" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5V19" stroke="#7a7a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M5 12H19" stroke="#7a7a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                <MinusIco v-else/>
              </div>
            </div>
            <span v-if="selectedRow.history" class="modal__attention__subtitle modal__attention__subtitle__history" :class="{'modal__attention__subtitle__history--active': openHistory}"  style="color:darkgrey;">{{selectedRow.history}}</span>
          </div>
          <div v-if="docs.length > 0" style="width: 100%">
            <span class="modal__attention__title">Документы</span>
            <ul>
              <li v-for="(item, index) in docs" :key="index"><a class="modal__attention__files" :href="`${BASE_URL}/file?id=${item.id}`" download>{{`${item.name}.${item.ext}`}}</a></li>
            </ul>
          </div>
          <div v-if="selectedRow.type_doc === 'Реестр платежей'">
            <span class="modal__attention__title">Реестр платежей</span>
            <ul>
              <li>
                <a class="modal__attention__files" :href="`${BASE_URL}/download?id=${selectedRow.id_doc}&type=registry`" target="_blank">Скачать</a>
              </li>
            </ul>
          </div>
          <div v-if="selectedRow.memo.type">
            <router-link v-if="selectedRow.memo.type === 'Лизинговая сделка'" :to="{ name: 'trade', params: { id: selectedRow.memo.link } }">Перейти в сделку</router-link>
            <router-link v-if="selectedRow.memo.type === 'Договор лизинга'" :to="{ name: 'contract', params: { id: selectedRow.memo.link } }">Перейти в договор</router-link>
          </div>
          <mq-layout :mq="['xs', 'md', 'lg', 'xlg', 'xl']" >
            <div class="modal__attention__btns" style="justify-content:center;">
              <button v-if="selectedRow.path_dot==='Исполнить'" class="modal__attention__btn modal__attention__btn--orange" @click="makeDone">Исполнено</button>
              <!-- <button v-if="selectedRow.path_dot==='Утвердить'" class="modal__attention__btn modal__attention__btn--green" @click="makeSign">Подписать</button>
              <button v-if="selectedRow.path_dot==='Утвердить'" class="modal__attention__btn modal__attention__btn--orange" @click="makeReject">Отклонить</button> -->
              <button v-if="selectedRow.path_dot==='Согласовать'" :disabled='noComment' class="modal__attention__btn modal__attention__btn--green" @click="makeApprove">Согласовано</button>
              <button v-if="selectedRow.path_dot==='Согласовать'" :disabled='noComment' class="modal__attention__btn modal__attention__btn--orange" @click="makeReject">Не согласовано</button>
              <button v-if="selectedRow.path_dot==='Проверить исполнение'" :disabled='noComment' class="modal__attention__btn modal__attention__btn--green" @click="makeDone">Проверено</button>
              <button v-if="selectedRow.path_dot==='Проверить исполнение'" :disabled='noComment' class="modal__attention__btn modal__attention__btn--orange" @click="makeReturnForRevision">Вернуть на доработку</button>
              <button v-if="selectedRow.path_dot.includes('Ознакомиться')" class="modal__attention__btn modal__attention__btn--green" @click="makeDone">Завершить</button>
              <button v-if="selectedRow.path_dot.includes('результат')  && selectedRow.result !== 'Согласовано'" class="modal__attention__btn modal__attention__btn--orange" @click="makeReturn">Повторить согласование</button>
              <button class="modal__attention__btn" @click="showModal = false">Закрыть</button>
            </div>
          </mq-layout>
          <mq-layout :mq="['sm', 'xsm']" class="modal__attention__btns modal__attention__btns--mobile">
            <div class="modal__attention__btns" style="justify-content:center;">
              <button v-if="selectedRow.path_dot==='Исполнить'" class="modal__attention__btn modal__attention__btn--orange" @click="makeDone">Исполнено</button>
              <!-- <button v-if="selectedRow.path_dot==='Утвердить'" class="modal__attention__btn modal__attention__btn--green" @click="makeSign">Подписать</button>
              <button v-if="selectedRow.path_dot==='Утвердить'" class="modal__attention__btn modal__attention__btn--orange" @click="makeReject">Отклонить</button> -->
              <button v-if="selectedRow.path_dot==='Согласовать'" :disabled='noComment' class="modal__attention__btn modal__attention__btn--green" @click="makeApprove">Согласовано</button>
              <button v-if="selectedRow.path_dot==='Согласовать'" :disabled='noComment' class="modal__attention__btn modal__attention__btn--orange" @click="makeReject">Не согласовано</button>
              <button v-if="selectedRow.path_dot==='Проверить исполнение'" :disabled='noComment' class="modal__attention__btn modal__attention__btn--green" @click="makeDone">Проверено</button>
              <button v-if="selectedRow.path_dot==='Проверить исполнение'" :disabled='noComment' class="modal__attention__btn modal__attention__btn--orange" @click="makeReturnForRevision">Вернуть на доработку</button>
              <button v-if="selectedRow.path_dot.includes('Ознакомиться')" class="modal__attention__btn modal__attention__btn--green" @click="makeDone">Завершить</button>
              <button v-if="selectedRow.path_dot.includes('результат')  && selectedRow.result !== 'Согласовано'" class="modal__attention__btn modal__attention__btn--orange" @click="makeReturn">Повторить согласование</button>
              <button class="modal__attention__btn" @click="showModal = false">Закрыть</button>
            </div>
          </mq-layout>
        </div>
      </div>
    </div>
    <div class="modal" v-if="$store.state.data.tasks.mobileModal.show">
      <div class="modal__back"></div>
      <div class="modal__body" style="margin: auto 0;">
        <div class="modal__attention">
          <div class="modal__attention__title">
            <span>Подтвердите действие</span>
          </div>
          <div class="modal__attention__container">
            <div class="modal__attention__btns" style="justify-content:center;">
              <!-- <button class="modal__attention__btn modal__attention__btn--green">{{ this.$store.state.data.tasks.mobileModal.data.path_dot }}</button> -->
              <button v-if="$store.state.data.tasks.mobileModal.data.path_dot==='Исполнить' && this.$store.state.data.tasks.mobileModal.data.event==='handleCardAccepted'" class="modal__attention__btn modal__attention__btn--orange" @click="makeDoneMobile">Исполнено</button>
              <button v-if="$store.state.data.tasks.mobileModal.data.path_dot==='Согласовать' && this.$store.state.data.tasks.mobileModal.data.event==='handleCardAccepted'" class="modal__attention__btn modal__attention__btn--green" @click="makeApproveMobile">Согласовано</button>
              <button v-if="$store.state.data.tasks.mobileModal.data.path_dot==='Согласовать' && this.$store.state.data.tasks.mobileModal.data.event==='handleCardRejected'" class="modal__attention__btn modal__attention__btn--orange" @click="makeRejectMobile">Не согласовано</button>
              <button v-if="$store.state.data.tasks.mobileModal.data.path_dot==='Проверить исполнение' && this.$store.state.data.tasks.mobileModal.data.event==='handleCardAccepted'" class="modal__attention__btn modal__attention__btn--green" @click="makeDoneMobile">Проверено</button>
              <button v-if="$store.state.data.tasks.mobileModal.data.path_dot==='Проверить исполнение' && this.$store.state.data.tasks.mobileModal.data.event==='handleCardRejected'" class="modal__attention__btn modal__attention__btn--orange" @click="makeReturnForRevisionMobile">Вернуть на доработку</button>
              <button v-if="$store.state.data.tasks.mobileModal.data.path_dot.includes('Ознакомиться') && this.$store.state.data.tasks.mobileModal.data.event==='handleCardAccepted'" class="modal__attention__btn modal__attention__btn--green" @click="makeDoneMobile">Завершить</button>
              <button v-if="$store.state.data.tasks.mobileModal.data.path_dot.includes('результат') && selectedRow.result !== 'Согласовано' && this.$store.state.data.tasks.mobileModal.data.event==='handleCardAccepted'" class="modal__attention__btn modal__attention__btn--orange" @click="makeReturnMobile">Повторить согласование</button>
              <button class="modal__attention__btn" @click="closeMobileModal">Отмена</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ApolloQuery :query="require('@/graphql/queries/TasksDo.gql')">
      <template slot-scope="{ result: { error, data }, isLoading }">
        <div class="messages apollo" v-if="isLoading">
          <div class="messages__status">Загрузка...</div>
        </div>
        <div class="messages apollo" v-else-if="error">
          <div class="messages__status error">Ошибка загрузки данных. Обратитесь в тех. поддержку</div>
        </div>
        <div v-else-if="data">
          <mq-layout :mq="['sm', 'xsm', 'md', 'lg']" v-if="$store.state.data.tasks.isSwipeTasks">
            <tasks-do-cards :visibleCards="data.allDoTasks" :showCardStack="$store.state.data.tasks.isSwipeTasks"/>
          </mq-layout>
          <vue-good-table
            v-else-if="data && data.allDoTasks"
            :columns="columns"
            :rows="data.allDoTasks"
            @on-row-click="onRowClick"
            compactMode
            :pagination-options="{
              enabled: true,
              perPage: 15,
              nextLabel: '',
              prevLabel: '',
              rowsPerPageLabel: 'Вывести',
              ofLabel: 'из',
              pageLabel: 'страниц',
              allLabel: 'Все',
            }"
            :sort-options="{
              enabled: true,
              initialSortBy: { field: 'date', type: 'desc' },
            }"
          >
            <div slot="emptystate">Задач нет</div>
              <div slot="table-actions" class="tbl__type">
                <a :class="{ active: $store.state.data.tasks.view === 'tableDo'}" @click.prevent="$store.state.data.tasks.view = 'tableDo'" href="#">
                  <TypeCone/>
                </a>
                <a :class="{ active: $store.state.data.tasks.view === 'table'}" @click.prevent="$store.state.data.tasks.view = 'table'" href="#">
                  <TypeTable/>
                </a>
                <mq-layout :mq="[ 'lg', 'xlg', 'xl' ]">
                  <a :class="{ active: $store.state.data.tasks.view === 'kanban'}" @click.prevent="$store.state.data.tasks.view = 'kanban'" href="#">
                    <TypeKanban/>
                  </a>
                </mq-layout>
              </div>
          </vue-good-table>
        </div>
      </template>
    </ApolloQuery>
    <div v-if="$store.state.me._id === '5ebbd7e19f7bd050e01dbf3f'" style="margin-top: 20px">
      <h3 style="text-align: center">
        Задачи Галимов Марсель Ринатович
      </h3>
      <ApolloQuery :query="require('@/graphql/queries/TasksDoEmployee.gql')">
        <template slot-scope="{ result: { error, data }, isLoading }">
          <div class="messages apollo" v-if="isLoading">
            <div class="messages__status">Загрузка...</div>
          </div>
          <div class="messages apollo" v-else-if="error">
            <div class="messages__status error">Ошибка загрузки данных. Обратитесь в тех. поддержку</div>
          </div>
          <div v-else-if="data">
            <vue-good-table
              :columns="columns"
              :rows="data.allEmployeeDoTasks"
              @on-row-click="onRowClick"
              compactMode
              :pagination-options="{
                enabled: true,
                perPage: 15,
                nextLabel: '',
                prevLabel: '',
                rowsPerPageLabel: 'Вывести',
                ofLabel: 'из',
                pageLabel: 'страниц',
                allLabel: 'Все',
              }"
              :sort-options="{
                enabled: true,
                initialSortBy: { field: 'date', type: 'desc' },
              }"
            >
              <div slot="emptystate">Задач нет</div>
            </vue-good-table>
          </div>
        </template>
      </ApolloQuery>
    </div>
  </div>
</template>

<script>
import TypeTable from '@/components/svg/TypeTable'
import TypeCone from '@/components/svg/TypeCone'
import TypeKanban from '@/components/svg/TypeKanban'
import VISAS from '@/graphql/queries/DocVisas.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import MinusIco from '@/components/svg/minus'
import gql from 'graphql-tag'
import axios from 'axios'
import CardsComponent from '@/components/ui/SwipeCards/CardsComponent'

const BASE_URL = process.env.VUE_APP_HTTP
const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'TasksDo',
  components: { TypeKanban, TypeTable, TypeCone, MinusIco, TasksDoCards: CardsComponent },
  data () {
    return {
      BASE_URL,
      message: '',
      subtitleExpanded: false,
      showModal: false,
      selectedRow: {},
      openHistory: false,
      history: [],
      columnsAgreement: [
        {
          label: 'Согласующее лицо',
          field: 'autor'
        },
        {
          label: 'Результат',
          field: 'result'
        },
        {
          label: 'Комментарий',
          field: 'comment'
        }
      ],
      columns: [
        {
          label: 'Дата',
          field: 'date',
          type: 'date',
          dateInputFormat: `yyyy-MM-dd'T'HH:mm:ss`, // expects 2018-03-16
          dateOutputFormat: 'dd.MM.yyyy HH:mm'
        },
        {
          label: 'Имя',
          field: 'fullname'
        },
        {
          label: 'Автор',
          field: 'autor'
        },
        {
          label: 'Комментарий',
          field: 'comment'
        }
      ],
      docs: [],
      content: ''
    }
  },
  computed: {
    noComment () {
      return !this.message
    }
  },
  methods: {
    closeMobileModal () {
      this.$store.state.data.tasks.mobileModal.show = false
      this.$store.state.data.tasks.mobileModal.data = null
      this.$store.state.data.tasks.mobileModal.newComment = null
    },
    async downloadFile (id) {
      await axios.get(`${BASE_URL}/file?id=${id}`)
    },
    async getDocs (id) {
      const docs = await axios.get(`${BASE_URL}/doc?uid=${id}`)
      return docs.data
    },
    async getContext (id) {
      const content = await axios.get(`${BASE_URL}/doc/data?id=${id}`)
      return content.data.content
    },
    async onRowClick (params) {
      this.selectedRow = params.row
      this.docs = await this.getDocs(params.row.doc)
      this.content = await this.getContext(params.row.doc)
      this.history = await this.getVisas(params.row.doc)
      this.showModal = true
    },
    getVisas (doc) {
      let history = []
      this.$apollo
        .mutate({
          mutation: VISAS,
          variables: {
            doc
          },
          update: (_, { data }) => {
            if (data && data.docVisas.length > 0) {
              this.history = [...data.docVisas]
            }
          }
        })
        .catch((error) => {
          _graphQlHelper.graphQLErrorMessages(error).forEach((item) => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
      return history
    },
    // makeSign () {
    //   this.showModal = false
    //   this.updateTask('sign') // Подписать (утвердить)
    // },
    makeDone () {
      this.showModal = false
      this.updateTask('done') // Исполнено или ознакомлено
    },
    makeApprove () {
      this.showModal = false
      this.updateTask('approve') // Согласовано
    },
    makeReject () {
      this.showModal = false
      this.updateTask('reject') // Не согласовано
    },
    makeReturn () {
      this.showModal = false
      this.updateTask('return') // Повторить согласование
    },
    makeReturnForRevision () {
      this.showModal = false
      this.updateTask('revision') // Доработка
    },
    makeDoneMobile () {
      this.showModal = false
      this.updateTaskMobile('done') // Исполнено или ознакомлено
    },
    makeApproveMobile () {
      this.showModal = false
      this.updateTaskMobile('approve') // Согласовано
    },
    makeRejectMobile () {
      this.showModal = false
      this.updateTaskMobile('reject') // Не согласовано
    },
    makeReturnMobile () {
      this.showModal = false
      this.updateTaskMobile('return') // Повторить согласование
    },
    makeReturnForRevisionMobile () {
      this.showModal = false
      this.updateTaskMobile('revision') // Доработка
    },
    async updateTaskMobile (type) {
      if (!this.$store.state.data.tasks.mobileModal.newComment) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Напишите комментарий'
        })
        return
      }
      await this.$apollo.mutate({
        mutation: gql`mutation updateDOTask($ids: [String!], $type: String, $comment:String) {
          updateDOTask (ids: $ids, type: $type, comment: $comment)
        }`,
        variables: {
          type,
          comment: this.$store.state.data.tasks.mobileModal.newComment,
          ids: [this.$store.state.data.tasks.mobileModal.data.id]
        }
      }).then(() => {
        this.closeMobileModal()
        return true
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    async updateTask (type) {
      await this.$apollo.mutate({
        mutation: gql`mutation updateDOTask($ids: [String!], $type: String, $comment:String) {
          updateDOTask (ids: $ids, type: $type, comment: $comment)
        }`,
        variables: {
          type,
          comment: this.message,
          ids: [this.selectedRow.id]
        }
      }).then(() => {
        this.$router.go()
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    }
  }
}
</script>

<style>
  .tag--task {
    display: inline-block
  }
  .footer__navigation__page-info {
    margin: auto 16px !important;
  }
  .modal__attention__btns--mobile {
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: #212529;
    height: 60px;
    padding: 10px;
    z-index: 999
  }
  /* .modal {
    align-items: center;
  } */
  .modal__body--task-do {
    max-width: 700px;
    margin-bottom: 70px
  }
  @media (max-width: 768px) {
    .modal__body--task-do {
      max-width: 90%;
      margin-bottom: 70px
    }
  }
  .modal__attention__files {
    cursor: pointer;
    color: #FF8000
  }
  .modal__attention__table {
    margin-bottom: 15px;
  }
  .modal__attention__subtitle__history {
    display: none
  }
  .modal__attention__subtitle__history--active {
    display: inline-block
  }
  .modal__attention__files:hover {
    text-shadow: 1px 1px 1px #FF8000
  }
  .modal__attention__container {
    overflow-y: scroll;
    white-space: pre-wrap;
    /* max-height: 600px; */
    width: 100%
  }
  .modal__attention__btn {
    background-color: antiquewhite;
    margin: 0px 5px;
  }
  .open {
    height: auto
  }
  button:disabled,
  button[disabled]{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    pointer-events: none;
  }
</style>
